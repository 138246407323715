
.ContainerForm {
  max-width: 1120px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 15px;
  box-shadow: 5px 5px 5px rgba(50, 50, 50, 0.514);
}

.ContainerForm-esc{
  display: flex;
  justify-content: space-around;
  grid-gap: 0.9rem;
}

.card-escolha {
  border-bottom: 3px solid #bbb;
  border-right: 3px solid #bbb;
  border-left: 1px solid #bbb;
  border-top: 1px solid #bbb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 300px;
  text-align: center;
  position: relative;
  float: left;
  cursor: pointer;

}

.card-escolha:hover {
  background-color: rgb(217, 217, 217);
}

.title-pergunta{
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.icon-escolha-otimo{
  font-size: 150px;
  margin-bottom: 30px;
  color: #35e535;
}

.icon-escolha-regular{
  font-size: 150px;
  margin-bottom: 30px;
  color: #ffae00;
}

.icon-escolha-ruim{
  font-size: 150px;
  margin-bottom: 30px;
  color: #ff0202;
}

@media (max-width: 750px) {
  .ContainerForm {
    width: 95%;
  }
  .title-pergunta{
    font-size: 16px;
  }

  .icon-escolha-otimo{
    font-size: 80px;
    margin-bottom: 15px;
    color: #35e535;
  }
  
  .icon-escolha-regular{
    font-size: 80px;
    margin-bottom: 15px;
    color: #ffae00;
  }
  
  .icon-escolha-ruim{
    font-size: 80px;
    margin-bottom: 15px;
    color: #ff0202;
  }

  .lab-escolha{
    font-size: 15px;
  }
  .card-escolha{
    width: 200px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}


.container-tab-chamad {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  width: 100%;


}

.input-box-chamado-novo input {
  width: 100%;
  height: 28px;
  padding: 5px;
  display: flex;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding-right: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  margin-top: 2px;
  margin-bottom: 10px;
  color: rgb(137, 137, 137);
}

.input-box-chamado-novo textarea{
  width: 100%;
  height: 110px;
  padding: 5px;
  display: flex;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding-right: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  margin-top: 2px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}
/*--------------------------------------

 /*------------------------------------------------------- */

.content-tabs {
  flex-grow: 1;
  
}

.content {
  background: white;
  width: 100%;
  height: 100%;
  display: none;
  
}

.active-content {
  display: block;
  
}

.div-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;

}

.btn-concluir{
  width: 30%;
  height: 40px;
  border-radius: 5px;
  background-color: #52b788;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
}

.btn-concluir:hover{
  background-color: #c5c5c5;
}

@media (max-width: 500px) {
  .ContainerForm-esc{
    grid-gap: 0.5rem;
  }
  .btn-concluir{
    width: 50%;}

    .title-pergunta{
      font-size: 18px;
    }

}

.icone-AgenOk{
  font-size: 120px;
  color: #ff0202;
}

.icone-concluir{
  display: flex;
  justify-content: center;
  align-items: center;
}

.lab-obg{
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.lab-obg2{
  text-align: center;
  font-size: 25px;
  color: #52b788;
}

.lab-obg3{
  color: rgb(14, 12, 12);
  font-size: 22px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #52b788;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
}

.lab-obg3:hover{
  opacity: 0.7;
}

.icone-clique{
  margin-left: 10px;
  font-size: 28px;
  margin-top: 10px;
  color: #000000;
}

.icone-concluir a{
  text-decoration:none;
  font-weight: bold;
}

.lab-obg3 a:hover{
  color: rgb(14, 12, 12);
}