

.Container{
  width: 100%;
  height: 25%;
  text-align: center;
  background: #52b788;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-top: 20px;
}

.Title{

  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: bolder;
  background-color: #fff;
  padding: 10px;
  margin-left: 20%;
  margin-right: 20%;
  box-shadow: 5px 5px 5px rgba(50, 50, 50, 0.514);
 border-radius: 5px;

}

@media (max-width: 450px) {
  .Container{
  padding-top: 40px;
}}


@media (max-width: 600px) {
  .Container{
    height: 20%;
    border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  }


  .Title{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .Title{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 18px;
  }
}
