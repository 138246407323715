.img-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
}

.logoNav{
    width:400px;
    height:140px
}

@media (max-width: 600px) {
    .logoNav{
        width:300px;
        height:100px;
        margin-top: 10%;
    }
     
}